import React from 'react'
import { PropTypes } from 'prop-types'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import useStyles from './styles/containerFields'

import useUtils from '../../../../shared/hooks/useUtils'

const GARANTIA_JUDICIAL = 'Garantia Judicial'
const SEGURO_GARANTIA = 'Seguro Garantia'
const CIVIL_RESPONSABILITY = 'Responsabilidade Civil'
const PATRIMONIAL = 'Patrimonial'
const ENDOSSO = 'Endosso'

const ContainerFields = ((props) => {
  const classes = useStyles()
  const { policy } = props
  const policyDetails = policy

  const {
    formatDate,
    formatCurrency,
    formatCPFOrCNPJ,
  } = useUtils()

  // Alterar para includes se tiver muitos produtos
  // [GARANTIA_JUDICIAL, SEGURO_GARANTIA,].includes(policyDetails?.PolicyData?.ProductName)
  const isModalityField = policyDetails?.PolicyData?.ProductName === GARANTIA_JUDICIAL
    || policyDetails?.PolicyData?.ProductName === SEGURO_GARANTIA
    || policyDetails?.PolicyData?.ProductName === CIVIL_RESPONSABILITY
    || policyDetails?.PolicyData?.ProductName === PATRIMONIAL

  const isTypeEndorsementField = policyDetails?.PolicyData?.TypeDocument === ENDOSSO

  const positionField = (a, b, c) => {
    if (isModalityField && isTypeEndorsementField) {
      return a
    }
    if (!isModalityField && isTypeEndorsementField) {
      return b
    }
    if (isModalityField && !isTypeEndorsementField) {
      return c
    }
    return 4
  }

  const typeDocumentLabel = 'Tipo de Documento'
  const brokerProductLabel = 'Produto'
  const brokerModalityLabel = 'Modalidade'
  const TypeOthersModalityLabel = 'Tipo de Modalidade'
  const dateOfIssueLabel = 'Data da Emissão'
  const guaranteeValueLabel = 'Importância Segurada / LMG'
  const startDateValidityLabel = 'Início de Vigência'
  const endDateValidityLabel = 'Término de Vigência'
  const endorsementTypeLabel = 'Tipo de Endosso'

  const BrokerNameLabel = 'Nome da Corretora'
  const BrokerCnpjLabel = 'CPF/CNPJ da Corretora'
  const InsuranceCompanyNameLabel = 'Nome da Seguradora'

  const nameInsuredLabel = 'Nome do Segurado'
  const cpfCnpjInsuredLabel = 'CPF/CNPJ do Segurado'

  const namePolicyHolderLabel = 'Nome do Cliente'
  const cpfCnpjPolicyHolderLabel = 'CPF/CNPJ do Cliente'

  const totalPrizeLabel = 'Prêmio Total'
  const premiumPaymentTypeLabel = 'Tipo de Pagamento do Prêmio'
  const installmentsNumberLabel = 'Quantidade de Parcelas'
  const mainPolicyNumberLabel = 'Número da Apólice Principal'
  const endorsedNumberLabel = 'Número do Documento Endossado'
  const policyNumberLabel = `Número ${policyDetails?.PolicyData?.TypeDocument === 'Apólice' ? 'da Apólice' : 'do Endosso'}`

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography
          gutterBottom
          className={classes.header}
          data-content="Dados da Apólice"
        />
        <Grid container className={classes.confirmationBox} spacing={2}>
          <Grid item xs={12} md={positionField(4, 3, 4)}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {typeDocumentLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {policyDetails?.PolicyData?.TypeDocument}
            </Typography>
          </Grid>
          {policyDetails?.PolicyData?.TypeDocument === ENDOSSO && (
            <Grid item xs={12} md={positionField(4, 3, 4)}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {mainPolicyNumberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {policyDetails?.PolicyData?.MainPolicyNumber}
              </Typography>
            </Grid>
          )}
          {policyDetails?.PolicyData?.TypeDocument === ENDOSSO && (
            <Grid item xs={12} md={positionField(4, 3, 4)}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {endorsedNumberLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {policyDetails?.PolicyData?.EndorsedNumber}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={positionField(4, 3, 8)}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {policyNumberLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {policyDetails?.PolicyData?.PolicyNumber}
            </Typography>
          </Grid>
          {policyDetails?.PolicyData?.TypeDocument === ENDOSSO && (
            <Grid item xs={12} md={positionField(4, 6, 4)}>
              <Typography gutterBottom className={classes.confirmationLabel}>
                {endorsementTypeLabel}
              </Typography>
              <Typography variant="subtitle1" className={classes.confirmationValue}>
                {policyDetails?.PolicyData?.EndorsementType}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={positionField(4, 3, 4)}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {brokerProductLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {policyDetails?.PolicyData?.ProductName}
            </Typography>
          </Grid>
          {
            (policyDetails?.PolicyData?.TypeOthersModality) && (
              <Grid item xs={12} md={positionField(6, 4, 4)}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {TypeOthersModalityLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {policyDetails?.PolicyData?.TypeOthersModality}
                </Typography>
              </Grid>
            )
          }
          {
            (policyDetails?.PolicyData?.ProductName === GARANTIA_JUDICIAL
              || policyDetails?.PolicyData?.ProductName === SEGURO_GARANTIA
              || policyDetails?.PolicyData?.ProductName === PATRIMONIAL
              || policyDetails?.PolicyData?.ProductName === CIVIL_RESPONSABILITY) && (
              <Grid item xs={12} md={positionField(4, 4, 4)}>
                <Typography gutterBottom className={classes.confirmationLabel}>
                  {brokerModalityLabel}
                </Typography>
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {policyDetails?.PolicyData?.ModalityName === 'Outros' ? policyDetails?.PolicyData?.ModalityOthersName : policyDetails?.PolicyData?.ModalityName}
                </Typography>
              </Grid>
            )
          }
          <Grid item xs={12} md={positionField(4, 3, 4)}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {InsuranceCompanyNameLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {policyDetails?.PolicyData?.InsuranceCompanyName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={positionField(4, 3, 4)}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {BrokerCnpjLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {formatCPFOrCNPJ(policyDetails?.PolicyData?.BrokerCnpj)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={positionField(4, 3, 4)}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {BrokerNameLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {policyDetails?.PolicyData?.BrokerName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.confirmationBox} spacing={2}>
          <Grid item xs={12} md={positionField(4, 3, 4)}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {startDateValidityLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {formatDate(policyDetails?.PolicyData?.StartDateValidity)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={positionField(4, 3, 4)}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {endDateValidityLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {formatDate(policyDetails?.PolicyData?.EndDateValidity)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={positionField(4, 3, 4)}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {dateOfIssueLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {formatDate(policyDetails?.PolicyData?.DateOfIssue)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {guaranteeValueLabel}
            </Typography>
            {policyDetails?.PolicyData?.GuaranteeValue !== 0
              && policyDetails?.PolicyData?.GuaranteeValue !== null
              && policyDetails?.PolicyData?.GuaranteeValue !== undefined
              && (
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {formatCurrency(policyDetails?.PolicyData?.GuaranteeValue)}
                </Typography>
              )}
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography
          gutterBottom
          className={classes.header}
          data-content="Dados do Cliente"
        />
        <Grid container className={classes.confirmationBox} spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {cpfCnpjPolicyHolderLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {formatCPFOrCNPJ(policyDetails?.PolicyHolder?.Document)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {namePolicyHolderLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {policyDetails?.PolicyHolder?.Name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography
          gutterBottom
          className={classes.header}
          data-content="Dados do Segurado"
        />
        <Grid container className={classes.confirmationBox} spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {cpfCnpjInsuredLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {formatCPFOrCNPJ(policyDetails?.Insured?.Document)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {nameInsuredLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {policyDetails?.Insured?.Name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography
          gutterBottom
          className={classes.header}
          data-content="Dados do Prêmio"
        />
        <Grid container className={classes.confirmationBox} spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {premiumPaymentTypeLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {policyDetails?.Payment?.PremiumPaymentType}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {installmentsNumberLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.confirmationValue}>
              {policyDetails?.Payment?.InstallmentsNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography gutterBottom className={classes.confirmationLabel}>
              {totalPrizeLabel}
            </Typography>
            {policyDetails?.Payment?.TotalPrize !== 0
              && policyDetails?.Payment?.TotalPrize !== null
              && (
                <Typography variant="subtitle1" className={classes.confirmationValue}>
                  {formatCurrency(policyDetails?.Payment?.TotalPrize)}
                </Typography>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})

ContainerFields.propTypes = {
  policy: PropTypes.any,
}

ContainerFields.defaultProps = {
  policy: undefined,
}

export default ContainerFields

import icoTrash from '../../assets/img/icons/status/icon_trash.png'
import icoNewSolicitation from '../../assets/img/icons/status/Property 1=new, Property 2=Circle.svg'
import icoLoading from '../../assets/img/icons/status/Property 1=black.svg'
import icoSend from '../../assets/img/icons/status/Property 1=enviado pott.svg'
import icoExpired from '../../assets/img/icons/status/Property 1=Cot. Expirada.svg'
import icoClose from '../../assets/img/icons/status/Property 1=close, Property 2=Circle.svg'
import icoSuccess from '../../assets/img/icons/status/Property 1=Sucess, Property 2=Circle.svg'
import icoAutomatico from '../../assets/img/icons/status/Property 1=Automatico.svg'
import icoAutomaticoTask from '../../assets/img/icons/status/Property 1=Automatico com task.svg'

import icoNewSolicitationContingency from '../../assets/img/icons/status/contingency/Property 1=new, Property 2=Circle.svg'
import icoLoadingContingency from '../../assets/img/icons/status/contingency/Property 1=black.svg'
import icoSendContingency from '../../assets/img/icons/status/contingency/Property 1=enviado pott.svg'
import icoExpiredContingency from '../../assets/img/icons/status/contingency/Property 1=Cot. Expirada.svg'
import icoCloseContingency from '../../assets/img/icons/status/contingency/Property 1=close, Property 2=Circle.svg'
import icoSuccessContingency from '../../assets/img/icons/status/contingency/Property 1=Sucess, Property 2=Circle.svg'
import icoAutomaticoContingency from '../../assets/img/icons/status/contingency/Property 1=Automatico.svg'
import icoClientNotNominated from '../../assets/img/icons/status/Property 1=sem nomeacao.svg'

import iconFinladia from '../../assets/img/logo-small.svg'
import iconAon from '../../assets/img/icons/ico-Aon.svg'
import iconMarsh from '../../assets/img/icons/icon-marsh.svg'
import iconWiz from '../../assets/img/icons/icon-wiz.png'
import icoBmg from '../../assets/img/icons/ico-bmg.png'
import icoJns from '../../assets/img/icons/icon-jns.png'
import icoAvla from '../../assets/img/icons/icon_avla.svg'
import icoEzze from '../../assets/img/icons/icon_ezze.png'
import icoNewe from '../../assets/img/icons/icon_newe.png'
import icoTokio from '../../assets/img/icons/ico-tokio.png'
import icoEssor from '../../assets/img/icons/ico_essor.png'
import icoSombrero from '../../assets/img/icons/ico_sombrero.png'
import icoExcelsior from '../../assets/img/icons/icon_excelsior.png'
import icoPottencial from '../../assets/img/icons/ico-pottencial.svg'
import icoPortoSeguro from '../../assets/img/icons/icon-porto-seguro.png'

export const DEFAULT_THEME = {
  id: 'default',
  primary: '#0E347D',
}

export const GUARANTEE_PERFORMANCE_PRIVATE = 1
export const GUARANTEE_PERFORMANCE_PUBLIC = 2

export const QUOTE_TYPE_AUTOMATIC = 1
export const QUOTE_TYPE_MANUAL = 2

export const QUOTE_BID = 1
export const QUOTE_LEASE_BAIL = 2
export const QUOTE_PERFORMANCE = 3
export const QUOTE_JUDICIAL_APPEAL = 4
export const QUOTE_ENGINEERING_RISK = 5
export const QUOTE_GUARANTEE_OTHER = 6

export const STATUS_QUOTE_NOT_SENDED = -1
export const STATUS_QUOTE_ANALIZING = 1
export const STATUS_QUOTE_APPROVED = 2
export const STATUS_QUOTE_DENIED = 3
export const STATUS_QUOTE_EXPIRED = 4

// export const STATUS_ALL = 0
// export const STATUS_NONE = null

export const STATUS_PROPOSAL_REQUESTED = 5
export const STATUS_PROPOSAL_DONE = 6
export const STATUS_PROPOSAL_ACCEPTED = 7
export const STATUS_PROPOSAL_EXPIRED = 8

export const STATUS_POLICY_ISSUED = 9
export const STATUS_POLICY_DONE = 'Emitida'
export const STATUS_POLICY_VIGENCY = 'Vigente'
export const STATUS_POLICY_DUE = 'Vencida'
export const STATUS_POLICY_CANCELED = 'Cancelado'

const TOOLTIP_STATUS_POLICY_DONE = 'São todas as apólices emitidas que período de vigência ainda não iniciou.'
const TOOLTIP_STATUS_POLICY_VIGENCY = 'São todas as apólices dentro do período de vigência. Obs: Considerando que a data de início de vigência será sempre a data da apólice principal, a data fim de vigência deverá considerar a maior data entre apólice e endossos. Caso o último endosso emitido seja um endosso de cancelamento a data fim de vigência será a data de início de vigência do endosso de cancelamento.'
const TOOLTIP_STATUS_POLICY_DUE = 'São todas as apólices vencidas que não foram prorrogadas. Obs.: Em que a data de vigência fim for menor que a data atual.'
const TOOLTIP_STATUS_POLICY_CANCELED = 'São documentos que foram cancelados através de um endosso. Obs.: Endosso de cancelamento emitido para a apólice principal.'

export const STATUS_POLICY = [
  {
    key: STATUS_POLICY_CANCELED,
    text: 'Cancelado',
    tooltip: TOOLTIP_STATUS_POLICY_CANCELED,
  },
  {
    key: STATUS_POLICY_DONE,
    text: 'Emitida',
    tooltip: TOOLTIP_STATUS_POLICY_DONE,
  },
  {
    key: STATUS_POLICY_DUE,
    text: 'Vencida',
    tooltip: TOOLTIP_STATUS_POLICY_DUE,
  },
  {
    key: STATUS_POLICY_VIGENCY,
    text: 'Vigente',
    tooltip: TOOLTIP_STATUS_POLICY_VIGENCY,
  },
]

export const STATUS_POLICY_ADJUSTED = [
  {
    key: STATUS_POLICY_CANCELED,
    text: 'Cancelada',
    tooltip: TOOLTIP_STATUS_POLICY_CANCELED,
  },
  {
    key: STATUS_POLICY_DONE,
    text: 'Emitida',
    tooltip: TOOLTIP_STATUS_POLICY_DONE,
  },
  {
    key: STATUS_POLICY_DUE,
    text: 'Vencida',
    tooltip: TOOLTIP_STATUS_POLICY_DUE,
  },
  {
    key: STATUS_POLICY_VIGENCY,
    text: 'Vigente',
    tooltip: TOOLTIP_STATUS_POLICY_VIGENCY,
  },
]

export const STATUS_ENDORSEMENT_ANALIZING = 10
export const STATUS_ENDORSEMENT_DENIED = 11
export const STATUS_ENDORSEMENT_ISSUED = 12
export const STATUS_EXCLUDED_FINISHED = 14
export const STATUS_EXCLUDED_FINISHED_EXECUTIVE = 16

export const ROLE_REGIONAL_FINL_SUL = 'FINLANDIA_SUL'
export const ROLE_REGIONAL_FINL_SAO_PAULO = 'FINLANDIA_SAO_PAULO'
export const ROLE_REGIONAL_FINL_RIO_MINAS = 'FINLANDIA_RIO_MINAS'
export const ROLE_REGIONAL_FINL_NORDESTE_NORTE = 'FINLANDIA_NORDESTE_NORTE'
export const ROLE_REGIONAL_FINL_CENTRAL_DE_VENDAS = 'FINLANDIA_CENTRAL_DE_VENDAS'
export const ROLE_REGIONAL_FINL_INTELIGENCIA_DE_DADOS = 'FINLANDIA_INTELIGENCIA_DE_DADOS'
export const ROLE_REGOINAL_FINL_FINLANDIA = 'FINLANDIA_FINLANDIA'
export const ROLE_REGIONAL_FINL_CENTRO_OESTE = 'FINLANDIA_CENTRO_OESTE'

// outras regionais a verificar
export const ROLE_REGIONAL_FINL_CORRETOR = 'FINLANDIA_CORRETOR_FINLANDIA'
export const ROLE_REGIONAL_FINL_USUARIO_INTEGRACAO = 'FINLANDIA_USUARIO_INTEGRACAO'
export const ROLE_REGIONAL_FINL_GESTOR_EMISSAO = 'FINLANDIA_GESTOR_EMISSAO'
export const ROLE_REGIONAL_FINL_CANCELAMENTO_INSTANCIA = 'FINLANDIA_CANCELAMENTO_INSTANCIA'
export const ROLE_REGIONAL_FINL_GESTOR_EMISSAO_RIO_MINAS_CO = 'FINLANDIA_GESTOR_EMISSAO_RIO_MINAS_CO'
export const ROLE_REGIONAL_FINL_SUPORTE_SISTEMAS = 'FINLANDIA_SUPORTE_SISTEMAS'
export const ROLE_REGIONAL_FINL_ACESSO_API = 'FINLANDIA_ACESSO_API'

export const ROLE_REGIONAL_NAME_SUL = 'SUL'
export const ROLE_REGIONAL_NAME_SAO_PAULO = 'SAO_PAULO'
export const ROLE_REGIONAL_NAME_RIO_MINAS = 'RIO_MINAS'
export const ROLE_REGIONAL_NAME_NORDESTE_NORTE = 'NORDESTE_NORTE'
export const ROLE_REGIONAL_NAME_CENTRAL_DE_VENDAS = 'CENTRAL_DE_VENDAS'
export const ROLE_REGIONAL_NAME_INTELIGENCIA_DE_DADOS = 'INTELIGENCIA_DE_DADOS'
export const ROLE_REGIONAL_NAME_FINLANDIA = 'FINLANDIA'
export const ROLE_REGIONAL_NAME_CENTRO_OESTE = 'CENTRO_OESTE'

export const ROLE_REGIONAL_NAME_ADJUSTED_SUL = 'SUL'
export const ROLE_REGIONAL_NAME_ADJUSTED_SAO_PAULO = 'SAO PAULO'
export const ROLE_REGIONAL_NAME_ADJUSTED_RIO_MINAS = 'RIO/MINAS'
export const ROLE_REGIONAL_NAME_ADJUSTED_NORDESTE_NORTE = 'NORDESTE/NORTE'
export const ROLE_REGIONAL_NAME_ADJUSTED_CENTRAL_DE_VENDAS = 'CENTRAL DE VENDAS'
export const ROLE_REGIONAL_NAME_ADJUSTED_INTELIGENCIA_DE_DADOS = 'INTELIGENCIA DE DADOS'
export const ROLE_REGIONAL_NAME_ADJUSTED_FINLANDIA = 'FINLANDIA'
export const ROLE_REGIONAL_NAME_ADJUSTED_CENTRO_OESTE = 'CENTRO OESTE'

export const EMISSION_PERFORMANCE_AUTO = '_18c790c2-7f61-4c9b-a87d-101737c47a40'
export const EMISSION_PERFORMANCE_MANUAL = '_2d19feec-c07c-4dcc-a2c2-863ccdf03e60'

export const ROLES_REGIONALS = [
  {
    id: ROLE_REGIONAL_FINL_SUL,
    name: ROLE_REGIONAL_NAME_SUL,
    nameAdjusted: ROLE_REGIONAL_NAME_ADJUSTED_SUL,
  },
  {
    id: ROLE_REGIONAL_FINL_SAO_PAULO,
    name: ROLE_REGIONAL_NAME_SAO_PAULO,
    nameAdjusted: ROLE_REGIONAL_NAME_ADJUSTED_SAO_PAULO,
  },
  {
    id: ROLE_REGIONAL_FINL_RIO_MINAS,
    name: ROLE_REGIONAL_NAME_RIO_MINAS,
    nameAdjusted: ROLE_REGIONAL_NAME_ADJUSTED_RIO_MINAS,
  },
  {
    id: ROLE_REGIONAL_FINL_NORDESTE_NORTE,
    name: ROLE_REGIONAL_NAME_NORDESTE_NORTE,
    nameAdjusted: ROLE_REGIONAL_NAME_ADJUSTED_NORDESTE_NORTE,
  },
  {
    id: ROLE_REGIONAL_FINL_CENTRAL_DE_VENDAS,
    name: ROLE_REGIONAL_NAME_CENTRAL_DE_VENDAS,
    nameAdjusted: ROLE_REGIONAL_NAME_ADJUSTED_CENTRAL_DE_VENDAS,
  },
  {
    id: ROLE_REGIONAL_FINL_INTELIGENCIA_DE_DADOS,
    name: ROLE_REGIONAL_NAME_INTELIGENCIA_DE_DADOS,
    nameAdjusted: ROLE_REGIONAL_NAME_ADJUSTED_INTELIGENCIA_DE_DADOS,
  },
  {
    id: ROLE_REGOINAL_FINL_FINLANDIA,
    name: ROLE_REGIONAL_NAME_FINLANDIA,
    nameAdjusted: ROLE_REGIONAL_NAME_ADJUSTED_FINLANDIA,
  },
  {
    id: ROLE_REGIONAL_FINL_CENTRO_OESTE,
    name: ROLE_REGIONAL_NAME_CENTRO_OESTE,
    nameAdjusted: ROLE_REGIONAL_NAME_ADJUSTED_CENTRO_OESTE,
  },
]

const GARANT_CODE = 'a83ec9ac-12a4-454a-a9f6-b2c65151f61f'

export const PRODUCT_JUDICIAL_DEPOSITO_RECURSAL = '85623a9e-86c3-4f70-889a-d449276cb036'
export const PRODUCT_JUDICIAL_DEPOSITO_RECURSAL_SUBSTITUICAO = '480d6b3d-eee6-48ab-9469-3442c4dec9c2'
export const PRODUCT_FIANCA_LOCATICIA = '1f929652-c8eb-4b04-af54-eed15d975b29'
export const PRODUCT_BID = 'ee819339-76c3-452e-b739-feb483da47cb'
export const PRODUCT_PERFORMANCE = 'a83ec9ac-12a4-454a-a9f6-b2c65151f61f'
export const PRODUCT_OTHER = '04e701fc-0098-4f05-969e-e0de9dc08592'
export const PRODUCT_ENG_RISK = '0cf8ccb1-e03c-49e4-9ab2-b77093b8fdd7'
export const PRODUCT_JUDICIAL_LABOR = 'd6fa26da-f365-4f83-9fcf-2b360724f8d6'
export const PRODUCT_JUDICIAL_GUARANTEE_CIVEL = '23395da3-19b5-4988-afe5-4beb9f6878d3'
export const PRODUCT_RESPONSABILIDADE_CIVIL = 'bcae14f8-7b11-4b82-b699-cec9b03d4141'

export const PRODUCT_RESPONSABILIDADE_CIVIL_PRESTACAO_SERVICO = '80bc676e-1bd1-4dc1-b27d-dc5bfc54cab1'
export const PRODUCT_RESPONSABILIDADE_CIVIL_PROFISSIONAL = 'a6c6da22-946c-40ee-a567-c73969607c78'
export const PRODUCT_RESPONSABILIDADE_CIVIL_OBRAS = 'e658ca36-7048-4eb2-9f98-614be6b9011b'
export const PRODUCT_RESPONSABILIDADE_CIVIL_DO = '3205ee29-3065-4da1-8a57-306dfb45a627'
export const PRODUCT_RESPONSABILIDADE_CIVIL_OPERACOES = '0821d75d-4cb4-4f2a-b106-629610b3d556'
export const PRODUCT_RESPONSABILIDADE_CIVIL_GERAL = '982676a0-35fa-48dc-a3da-57199a984074'
export const PRODUCT_RESPONSABILIDADE_CIVIL_RISCOS_CIBERNETICOS = '4779d02a-f9db-4a03-9334-a98a78cdbf95'
export const PRODUCT_RESPONSABILIDADE_CIVIL_AMBIENTAL = 'adcd6489-d10f-4e0c-b731-65a2a2d3f14d'
export const PRODUCT_RESPONSABILIDADE_CIVIL_EVENTOS = '7eab2c1e-252a-4d70-92ce-4739eaa7aa52'

export const PRODUCT_PATRIMONIAL_AUTOMOVEL = '8c833476-4b09-4ecd-a57d-314a2db77bed'
export const PRODUCT_PATRIMONIAL_EMPRESARIAL = '9fd5a2a6-9181-4e93-a750-917050f4a39f'
export const PRODUCT_PATRIMONIAL_RISCOS_DIVERSOS = 'fe19a71c-774f-4016-b550-360ad272243e'
export const PRODUCT_PATRIMONIAL_RETA = '8fecf120-609a-4188-bdac-58a896e45de3'
export const PRODUCT_PATRIMONIAL_OPERADOR_AEROPORTUARIO = '78855df5-ceb2-45a4-b135-d2a0e2d0df4b'
export const PRODUCT_PATRIMONIAL_RESIDENCIAL = 'e0dc6faa-042e-415c-a276-b298e7cd7a23'
export const PRODUCT_PATRIMONIAL_RCTRP = '578484a9-66b0-4553-a09d-5f4987cb9c08'
export const PRODUCT_PATRIMONIAL_CONDOMINIO = '9e1106e3-3621-4801-a102-849bb6ac3a1a'

export const PRODUCT_PATRIMONIAL = 'f8f16a76-99ed-49f1-8ddd-1874f0db23ac'

export const PRODUCTS_LICIT = [
  GARANT_CODE,
  '8d0cc731-5fa6-438d-bae7-35a51472c9fc',
]
export const PRODUCTS_CONSTRUCTION = [
  '7f6f07f2-ffc6-4591-bfa2-09ec49168aa2',
  '0040b17d-90cf-405a-8962-07cc3f19d9cb',
]

export const BLOCKED_USER = 'BlockedUser'
export const ERROR_PASSWORD_ENGLISH = 'PasswordMismatch'
export const ERROR_NO_USER_RECORD_ENGLISH = 'UserNotFound'

export const ATENDER_SOLICITACAO_DE_ENDOSSO = '8db50af3-f373-4e7f-bee1-810020608ba5'
export const REGISTRAR_ENDOSSO_EMITIDO = 'a6e47413-af00-482f-803c-b735dc8265de'

export const ANALISAR_SOLICITACAO_DE_COTACAO_OG = 'eb8c22bd-e328-4358-9118-7f26d8ed1a9b'
export const REGISTRAR_APOLICE_EMITIDA_OG = 'ff0f22bb-f8a9-47d1-8b83-4185f7d76535'

export const REALIZAR_COTACAO_NA_SEGURADORA_FL = '653eb342-568b-4ea9-8041-690025334a41'
export const SOLICITAR_PROPOSTA_NA_SEGURADORA_FL = '358d118b-0586-45c4-a9b5-bc6fee5ea09e'

export const REGISTRAR_ACEITE_DE_PROPOSTA_FL = '77871e63-b474-49e2-9344-8dd6a4ec7752'
export const MONITORAR_EMISSAO_DA_APOLICE_FL = '039d14cf-519e-4693-9fd2-c3fd3e893324'

export const AGUARDAR_RETORNO_DO_EXECUTIVO = '62353f3d-7d9c-4a60-94d9-3fbc58b9b40b'
export const REGISTRAR_APOLICE_EMITIDA = 'e85f85ae-afbd-4ff8-8864-ba80cd413831'

export const SOLICITACAO_DE_ANALISE = 'ca84909d-de83-4ae3-bb12-f4e7c0007ce5'
export const ANALISE_REGISTRAR_APOLICE_EMITIDA = '6c48b5ff-2ce8-4006-8e7e-fc83264916c6'

export const SEGUIR_COM_COTACAO_LIBERADA = '9a8e8f26-a04c-456f-95c7-6289d6f75ea5'

export const CONTINGENCIA = 'Contingência'
export const EM_PROCESSAMENTO = 'Em processamento'
export const NOVA_SOLICITACAO = 'Nova Solicitação'
export const REANALISE_CLIENTE = 'Reanálise Cliente'
export const ENVIADO_A_POTTENCIAL = 'Enviado a Pottencial'
export const SOLICITACAO_EXPIRADA = 'Solicitação Expirada'
export const SOLICITACAO_EXPIRADA_AUTOMATICO = 'Solicitação Expirada - Automático'
export const SOLICITACAO_NEGADA = 'Solicitação Negada'
export const APOLICE_EMITIDA = 'Apólice Emitida'
export const EM_ANALISE = 'Em Análise'
export const EM_ANALISE_AUTOMATICO = 'Em Análise - Automático'
export const COTACAO_LIBERADA = 'Emitir apólice'
export const COTACAO_LIBERADA_AUTOMATICO = 'Emitir apólice'
export const AGUARDANDO_EMISSAO = 'Aguardando Emissão'
export const AGUARDANDO_EMISSAO_AUTOMATICO = 'Aguardando Emissão - Automático'
export const EXCLUIDO_PELO_CLIENTE = 'Excluído pelo Cliente'
export const CLIENTE_SEM_NOMECAO = 'Cliente Sem Nomeação'
export const EXCLUIDO_PELO_EXECUTIVO = 'Excluído pelo Executivo'
export const ENVIADO_AO_SALESFORCE = 'Enviado ao SalesForce'
export const NOVA_OUTRA_CORRETORA = 'Outra Corretora'
export const FINALIZADA_OUTRA_CORRETORA = 'Finalizado pelo Executivo'
export const STATUS_FINANCIAL_PAID = 'Paid'
export const STATUS_FINANCIAL_PENDING = 'Pending'
export const STATUS_FINANCIAL_DUE = 'Expired'

export const FIANCA_LOCATICIA_NAME = 'Fiança Locatícia'

export const GARANTIA_JUDICIAL_NAME = 'Garantia Judicial'
export const PATRIMONIAL_NAME = 'Patrimonial'
export const PRODUCT_RESPONSABILIDADE_CIVIL_NAME = 'Responsabilidade Civil'

export const OPTIONS_QUOTATION_STATUS_FILTER = [
  AGUARDANDO_EMISSAO_AUTOMATICO,
  APOLICE_EMITIDA,
  CONTINGENCIA,
  CLIENTE_SEM_NOMECAO,
  COTACAO_LIBERADA_AUTOMATICO,
  EM_ANALISE_AUTOMATICO,
  EM_PROCESSAMENTO,
  ENVIADO_A_POTTENCIAL,
  ENVIADO_AO_SALESFORCE,
  EXCLUIDO_PELO_CLIENTE,
  EXCLUIDO_PELO_EXECUTIVO,
  FINALIZADA_OUTRA_CORRETORA,
  NOVA_SOLICITACAO,
  NOVA_OUTRA_CORRETORA,
  REANALISE_CLIENTE,
  SOLICITACAO_EXPIRADA_AUTOMATICO,
  SOLICITACAO_NEGADA,
]

export const ICONS_CONTINGENCY = {
  'Atender nova solicitação': icoNewSolicitationContingency,
  'Analisar solicitação do cliente': icoLoadingContingency,
  'Registrar apólice emitida': icoSendContingency,
  Finalizado: icoCloseContingency,
  'Apólice Emitida': icoSuccessContingency,
  'Solicitação Expirada': icoExpiredContingency,
  '': icoAutomaticoContingency,
  'Atender solicitação de endosso': icoNewSolicitationContingency,
  'Emitir apólice automática': icoAutomaticoContingency,
  ' ': icoAutomaticoContingency,
}

export const PREFIX_RETURN_PRODUCT = {
  JC: GARANTIA_JUDICIAL_NAME,
  JT: GARANTIA_JUDICIAL_NAME,
  JE: GARANTIA_JUDICIAL_NAME,
  JDR: GARANTIA_JUDICIAL_NAME,
  RE: 'Risco Engenharia',
  FL: FIANCA_LOCATICIA_NAME,
  EMP: PATRIMONIAL_NAME,
  AUT: PATRIMONIAL_NAME,
  RD: PATRIMONIAL_NAME,
  RETA: PATRIMONIAL_NAME,
  RCO: PATRIMONIAL_NAME,
  RES: PATRIMONIAL_NAME,
  RCT: PATRIMONIAL_NAME,
  CON: PATRIMONIAL_NAME,
  RCPS: PRODUCT_RESPONSABILIDADE_CIVIL_NAME,
  RCP: PRODUCT_RESPONSABILIDADE_CIVIL_NAME,
  RCOB: PRODUCT_RESPONSABILIDADE_CIVIL_NAME,
  RCDO: PRODUCT_RESPONSABILIDADE_CIVIL_NAME,
  RCOP: PRODUCT_RESPONSABILIDADE_CIVIL_NAME,
  RCG: PRODUCT_RESPONSABILIDADE_CIVIL_NAME,
  RCRC: PRODUCT_RESPONSABILIDADE_CIVIL_NAME,
  RCA: PRODUCT_RESPONSABILIDADE_CIVIL_NAME,
  RCE: PRODUCT_RESPONSABILIDADE_CIVIL_NAME,
}

export const PREFIX_RETURN_MODALITY = {
  FL: '',
  RE: '',
  BID: 'Licitante (BID)',
  PER: 'Performance (Construção, Fornecimento ou Prestação de Serviços)',
  JC: 'Judicial Cível',
  JT: 'Judicial Trabalhista',
  JE: 'Judicial Execução Fiscal',
  JDR: 'Judicial Depósito Recursal',
  OG: 'Outros',
  EMP: 'Empresarial',
  AUT: 'Automóvel',
  RD: 'Riscos Diversos - Equipamentos',
  RETA: 'Reta',
  RCO: 'Rc Operador Aeroportuário',
  RES: 'Residencial',
  RCT: 'RCTR-P Municipal/Intermunicipal',
  CON: 'Condominio',
  RCPS: 'Rc Prestação De Serviços',
  RCP: 'Rc Profissional',
  RCOB: 'Rc Obras',
  RCDO: 'D&O',
  RCOP: 'Rc Operações',
  RCG: 'Rc Geral',
  RCRC: 'Riscos Ciberneticos',
  RCA: 'Rc Ambiental',
  RCE: 'Rc Eventos',
}

export const PREFIX_RETURN_PRODUCT_KEY = {
  JC: 'judicial-civel',
  JT: 'judicial-trabalhista',
  JE: 'garantia',
  JDR: 'judicial-deposito-recursal',
  RE: 'risco-engenharia',
  FL: 'fianca-locaticia',
}

export const STATUS_FINANCIAL = [
  {
    key: STATUS_FINANCIAL_PENDING,
    text: 'À Vencer',
  },
  {
    key: STATUS_FINANCIAL_PAID,
    text: 'Pago',
  },
  {
    key: STATUS_FINANCIAL_DUE,
    text: 'Vencido',
  },
]

export const PRODUCTS_QUOTATION = [

  {
    key: PRODUCT_FIANCA_LOCATICIA,
    text: FIANCA_LOCATICIA_NAME,
  },
  {
    key: PRODUCT_BID,
    text: 'Garantia Licitante',
  },
  {
    key: PRODUCT_PERFORMANCE,
    text: 'Garantia Performance',
  },
  {
    key: PRODUCT_JUDICIAL_GUARANTEE_CIVEL,
    text: 'Judicial Cível',
  },
  {
    key: PRODUCT_JUDICIAL_DEPOSITO_RECURSAL,
    text: 'Judicial Deposito Recursal',
  },
  {
    key: PRODUCT_JUDICIAL_DEPOSITO_RECURSAL_SUBSTITUICAO,
    text: 'Judicial Deposito Recursal Substituição',
  },
  {
    key: PRODUCT_JUDICIAL_LABOR,
    text: 'Judicial Trabalhista',
  },
  {
    key: PRODUCT_OTHER,
    text: 'Outras Garantias',
  },
  {
    key: PRODUCT_ENG_RISK,
    text: 'Risco de Engenharia ',
  },
]

export const STATUS_QUOTATION = [
  {
    text: CONTINGENCIA,
  },
  {
    Automatic: 'false',
    IdForm: REALIZAR_COTACAO_NA_SEGURADORA_FL,
    Status: STATUS_QUOTE_ANALIZING,
    text: NOVA_SOLICITACAO,
  },
  {
    Automatic: 'false',
    IdForm: ANALISAR_SOLICITACAO_DE_COTACAO_OG,
    Status: STATUS_QUOTE_ANALIZING,
    text: NOVA_SOLICITACAO,
  },
  {
    Automatic: 'false',
    IdForm: AGUARDAR_RETORNO_DO_EXECUTIVO,
    Status: STATUS_QUOTE_ANALIZING,
    text: NOVA_SOLICITACAO,
  },
  {
    Automatic: 'false',
    IdForm: ATENDER_SOLICITACAO_DE_ENDOSSO,
    Status: STATUS_ENDORSEMENT_ANALIZING,
    text: NOVA_SOLICITACAO,
  },
  {
    Automatic: 'false',
    IdForm: SOLICITAR_PROPOSTA_NA_SEGURADORA_FL,
    Status: STATUS_PROPOSAL_REQUESTED,
    text: NOVA_SOLICITACAO,
  },
  {
    Automatic: 'false',
    IdForm: REGISTRAR_ACEITE_DE_PROPOSTA_FL,
    Status: STATUS_PROPOSAL_ACCEPTED,
    text: NOVA_SOLICITACAO,
  },
  {
    Automatic: 'false',
    IdForm: SOLICITACAO_DE_ANALISE,
    Status: STATUS_QUOTE_ANALIZING,
    text: REANALISE_CLIENTE,
  },
  {
    Automatic: 'false',
    IdForm: MONITORAR_EMISSAO_DA_APOLICE_FL,
    Status: STATUS_PROPOSAL_ACCEPTED,
    text: ENVIADO_A_POTTENCIAL,
  },
  {
    Automatic: 'false',
    IdForm: REGISTRAR_APOLICE_EMITIDA,
    Status: STATUS_QUOTE_ANALIZING,
    text: ENVIADO_A_POTTENCIAL,
  },
  {
    Automatic: 'false',
    IdForm: ANALISE_REGISTRAR_APOLICE_EMITIDA,
    Status: STATUS_QUOTE_ANALIZING,
    text: ENVIADO_A_POTTENCIAL,
  },
  {
    Automatic: 'false',
    IdForm: REGISTRAR_APOLICE_EMITIDA_OG,
    Status: STATUS_QUOTE_ANALIZING,
    text: ENVIADO_A_POTTENCIAL,
  },
  {
    Automatic: 'false',
    IdForm: REGISTRAR_ENDOSSO_EMITIDO,
    Status: STATUS_ENDORSEMENT_ANALIZING,
    text: ENVIADO_A_POTTENCIAL,
  },
  {
    Automatic: 'true',
    IdForm: '',
    Status: STATUS_POLICY_ISSUED,
    text: APOLICE_EMITIDA,
  },
  {
    Automatic: 'true',
    IdForm: ' ',
    Status: STATUS_POLICY_ISSUED,
    text: APOLICE_EMITIDA,
  },
  {
    Automatic: 'false',
    IdForm: '',
    Status: STATUS_POLICY_ISSUED,
    text: APOLICE_EMITIDA,
  },
  {
    Automatic: 'false',
    IdForm: ' ',
    Status: STATUS_POLICY_ISSUED,
    text: APOLICE_EMITIDA,
  },
  {
    Automatic: 'false',
    IdForm: '',
    Status: STATUS_ENDORSEMENT_ISSUED,
    text: APOLICE_EMITIDA,
  },
  {
    Automatic: 'false',
    IdForm: ' ',
    Status: STATUS_ENDORSEMENT_ISSUED,
    text: APOLICE_EMITIDA,
  },
  {
    Automatic: 'true',
    IdForm: '',
    Status: STATUS_QUOTE_DENIED,
    text: SOLICITACAO_NEGADA,
  },
  {
    Automatic: 'true',
    IdForm: '',
    Status: STATUS_ENDORSEMENT_DENIED,
    text: SOLICITACAO_NEGADA,
  },
  {
    Automatic: 'true',
    IdForm: '',
    Status: STATUS_QUOTE_EXPIRED,
    text: SOLICITACAO_EXPIRADA_AUTOMATICO,
  },
  {
    Automatic: 'true',
    IdForm: '',
    Status: STATUS_PROPOSAL_EXPIRED,
    text: SOLICITACAO_EXPIRADA_AUTOMATICO,
  },
  {
    Automatic: 'true',
    IdForm: '',
    Status: STATUS_QUOTE_ANALIZING,
    text: EM_ANALISE_AUTOMATICO,
  },
  {
    Automatic: 'true',
    IdForm: '',
    Status: STATUS_QUOTE_APPROVED,
    text: COTACAO_LIBERADA_AUTOMATICO,
  },
  {
    Automatic: 'true',
    IdForm: '',
    Status: STATUS_PROPOSAL_REQUESTED,
    text: AGUARDANDO_EMISSAO_AUTOMATICO,
  },
  {
    Automatic: 'true',
    IdForm: '',
    Status: STATUS_PROPOSAL_DONE,
    text: AGUARDANDO_EMISSAO_AUTOMATICO,
  },
]

const TOOLTIP_EM_PROCESSAMENTO = 'Em processamento aguarde...'
const TOOLTIP_CONTINGENCIA = 'Aconteceu um erro, favor seguir com a solicitação manualmente'
const TOOLTIP_NOVA_SOLICITACAO = 'Nova solicitação não automática'
const TOOLTIP_REANALISE_CLIENTE = 'Cliente solicitou a análise do executivo'
const TOOLTIP_ENVIADO_A_POTTENCIAL = 'Cotação sendo tratada manualmente no Portal do Corretor, aguardando registro manual da apólice'
const TOOLTIP_SOLICITACAO_EXPIRADA = 'Cotação de nova apólice ou endosso expirada'
const TOOLTIP_SOLICITACAO_NEGADA = 'Cotação de nova apólice ou endosso negada'
const TOOLTIP_AGUARDANDO_EMISSAO = 'Cliente solicitou emissão automática e a apólice está em processo de emissão'
const TOOLTIP_EM_ANALISE = 'Solicitação em processo de análise automática'
const TOOLTIP_COTACAO_LIBERADA = 'Solicitação aguardando prosseguimento do cliente para emissão automática'
const TOOLTIP_EXCLUIDO_PELO_CLIENTE = 'Solicitação Excluída pelo Cliente'
const TOOLTIP_EXCLUIDO_PELO_EXECUTIVO = 'Solicitação Excluída pelo Executivo'

export const TOOLTIP_ENVIADO_AO_SALESFORCE = 'Solicitação Enviada para a Área Técnica do SalesForce'

export const COLOR_NEW_SOLICITATION = '#375ea8'
export const COLOR_BTN_FILTER = '#5785DC'
export const COLOR_LOADING = '#375ea8'
export const COLOR_SEND = '#f99c4d'
export const COLOR_EXPIRED = '#71777f'
export const COLOR_CLOSE = '#ff3c3c'
export const COLOR_BLACK = '#000000'
export const COLOR_ERROR = COLOR_CLOSE
export const COLOR_SUCCESS = '#34c576'
export const COLOR_AUTOMATIC = '#b7b8b9'
export const COLOR_CONTIGENCIA = '#d32f2f'
export const COLOR_PROCESSAMENTO = '#3F4159'
export const COLOR_QUOTE_RELEASED_AUTO_TASK = '#3F4159'
export const COLOR_DELETE = '#fa1c1c'
export const COLOR_SUBSCRIPTION_ANALYSIS = '#507010'

export const STATUS_QUOTATION_LIST = [
  {
    text: NOVA_SOLICITACAO,
    tooltip: TOOLTIP_NOVA_SOLICITACAO,
    icon: icoNewSolicitation,
    color: COLOR_NEW_SOLICITATION,
  },
  {
    text: REANALISE_CLIENTE,
    tooltip: TOOLTIP_REANALISE_CLIENTE,
    icon: icoLoading,
    color: COLOR_LOADING,
  },
  {
    text: ENVIADO_A_POTTENCIAL,
    tooltip: TOOLTIP_ENVIADO_A_POTTENCIAL,
    icon: icoSend,
    color: COLOR_SEND,
  },
  {
    text: COTACAO_LIBERADA,
    tooltip: TOOLTIP_COTACAO_LIBERADA,
    icon: icoAutomaticoTask,
    color: COLOR_QUOTE_RELEASED_AUTO_TASK,
  },
  {
    text: SOLICITACAO_EXPIRADA,
    tooltip: TOOLTIP_SOLICITACAO_EXPIRADA,
    icon: icoExpired,
    color: COLOR_EXPIRED,
  },
  {
    text: SOLICITACAO_NEGADA,
    tooltip: TOOLTIP_SOLICITACAO_NEGADA,
    icon: icoClose,
    color: COLOR_CLOSE,
  },
  {
    text: APOLICE_EMITIDA,
    tooltip: '',
    icon: icoSuccess,
    color: COLOR_SUCCESS,
  },
  {
    text: EM_ANALISE,
    tooltip: TOOLTIP_EM_ANALISE,
    icon: icoAutomatico,
    color: COLOR_AUTOMATIC,
  },
  {
    text: COTACAO_LIBERADA,
    tooltip: TOOLTIP_COTACAO_LIBERADA,
    icon: icoAutomatico,
    color: COLOR_AUTOMATIC,
  },
  {
    text: APOLICE_EMITIDA,
    tooltip: '',
    icon: icoSuccess,
    color: COLOR_SUCCESS,
  },
  {
    text: AGUARDANDO_EMISSAO,
    tooltip: TOOLTIP_AGUARDANDO_EMISSAO,
    icon: icoAutomatico,
    color: COLOR_AUTOMATIC,
  },
  {
    text: EM_PROCESSAMENTO,
    tooltip: TOOLTIP_EM_PROCESSAMENTO,
    icon: '',
    color: COLOR_PROCESSAMENTO,
  },
  {
    text: CONTINGENCIA,
    tooltip: TOOLTIP_CONTINGENCIA,
    icon: '',
    color: COLOR_CONTIGENCIA,
  },
  {
    text: EXCLUIDO_PELO_CLIENTE,
    tooltip: TOOLTIP_EXCLUIDO_PELO_CLIENTE,
    icon: icoTrash,
    color: COLOR_DELETE,
  },
  {
    text: EXCLUIDO_PELO_EXECUTIVO,
    tooltip: TOOLTIP_EXCLUIDO_PELO_EXECUTIVO,
    icon: icoTrash,
    color: COLOR_DELETE,
  },
  {
    text: CLIENTE_SEM_NOMECAO,
    tooltip: '',
    icon: icoClientNotNominated,
    color: COLOR_NEW_SOLICITATION,
  },
  {
    text: NOVA_OUTRA_CORRETORA,
    tooltip: '',
    icon: icoNewSolicitation,
    color: COLOR_NEW_SOLICITATION,
  },
  {
    text: FINALIZADA_OUTRA_CORRETORA,
    tooltip: '',
    icon: icoTrash,
    color: COLOR_DELETE,
  },
]

export const YEARS_IN_FIRST_LOAD = 3

export const MONTH_OPTIONS = [
  {
    id: '',
    text: 'Nenhum',
  },
  {
    id: 1,
    text: 'Janeiro',
  },
  {
    id: 2,
    text: 'Fevereiro',
  },
  {
    id: 3,
    text: 'Março',
  },
  {
    id: 4,
    text: 'Abril',
  },
  {
    id: 5,
    text: 'Maio',
  },
  {
    id: 6,
    text: 'Junho',
  },
  {
    id: 7,
    text: 'Julho',
  },
  {
    id: 8,
    text: 'Agosto',
  },
  {
    id: 9,
    text: 'Setembro',
  },
  {
    id: 10,
    text: 'Outubro',
  },
  {
    id: 11,
    text: 'Novembro',
  },
  {
    id: 12,
    text: 'Dezembro',
  },

]

export const CACHE_MESSAGE = 'O Cache ainda está sendo processado. Por favor aguarde mais alguns instantes.'
export const MESSAGE_AUTH_USER_ERRO = 'Acesso não autorizado! Usuário não cadastrado no Salesforce. Entre em contato com administrador do sistema. '
export const MSG_NOT_FOUND = '* CPF/CNPJ não encontrado.'
export const MSG_NOT_FOUND_NAME_SOCIAL = '* Razão Social não encontrada.'
export const MSG_REQUIRED = '* Campo obrigatório.'

export const VALUES_ORDER = [
  {
    value: 11,
    text: 'Mais Novo',
  },
  {
    value: 12,
    text: 'Mais Antigo',
  },
  {
    value: 13,
    text: 'Maior Importância segurada',
  },
  {
    value: 14,
    text: 'Menor Importância segurada',
  },
]

export const VALUES_ORDER_POLICY = [
  {
    value: 1,
    text: 'Mais Novo',
  },
  {
    value: 2,
    text: 'Mais Antigo',
  },
  {
    value: 3,
    text: 'Maior Importância segurada',
  },
  {
    value: 4,
    text: 'Menor Importância segurada',
  },
]

export const INSURANCE_COMPANY = [
  {
    id: 'cf906d07-1503-45a3-b90e-22ee33a0dbbe',
    name: 'Tokio',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoTokio,
      styles: {
        width: 87,
        height: 60,
        marginTop: -5,
        marginLeft: 23,
      },
    },
  },
  {
    id: 'ddd76733-47b3-42fe-a25c-4786e23c454a',
    name: 'Porto Seguro',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoPortoSeguro,
      styles: {
        height: 30,
        width: 35,
      },
    },
  },
  {
    id: '2003f3a1-7b01-440e-9c0e-f64ee4d994d2',
    name: 'Pottencial',
    icon: {
      svg: icoPottencial,
      style: {
        height: 22,
        width: 22,
      },
    },
  },
  {
    id: '3cd197ce-1bbb-45f8-9910-8ba246904642',
    name: 'Pottencial',
    icon: {
      svg: icoPottencial,
      style: {
        height: 22,
        width: 22,
      },
    },
  },
  {
    id: 'd044fc59-2034-4d9e-a8fe-6439fd3b55e9',
    name: 'Essor',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoEssor,
      styles: {
        width: 16,
        height: 26,
        marginRight: 10,
      },
    },
  },
  {
    id: '139f9681-4d0e-4c2c-b552-181f994e048a',
    name: 'Excelsior',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoExcelsior,
      styles: {
        width: 26,
        height: 21,
        marginRight: 4,
      },
    },
  },
  {
    id: 'a268fc69-aa3f-4bf3-a71d-3452e550918c',
    name: 'BMG',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoBmg,
      styles: {
        width: 23,
        height: 27,
        marginRight: 4,
      },
    },
  },
  {
    id: '58526325-944d-45d5-8df3-91b97ca4fd05',
    name: 'JNS',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoJns,
      styles: {
        width: 35,
        height: 18,
        marginRight: 4,
      },
    },
  },
  {
    id: '3c0a0cae-335c-41e7-97b6-dd9891791eb5',
    name: 'EZZE',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoEzze,
      styles: {
        width: 40,
        height: 16,
        marginRight: 4,
      },
    },
  },
  {
    id: 'c69e5d7a-5437-485e-948a-a5f6a1df5fdc',
    name: 'Newe',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoNewe,
      styles: {
        width: 50,
        height: 19,
        marginRight: 4,
      },
    },
  },
  {
    id: '0abd11e1-5695-44ef-812b-7d35f9d99b87',
    name: 'Sombrero',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: icoSombrero,
      styles: {
        width: 25,
        height: 20,
        marginRight: 4,
      },
    },
  },
  {
    id: '841e57ad-f32b-49f3-8140-12deee257ab8',
    name: 'Avla',
    icon: {
      svg: icoAvla,
      style: {
        height: 22,
        width: 22,
      },
    },
  },
]

export const BROKERS = [
  {
    cnpj: '10864690000180',
    name: 'Finlândia',
    fullName: 'Finlândia Corretora de Seguros Ltda',
    icon: {
      svg: iconFinladia,
      style: {
        height: 24,
        width: 24,
      },
    },
  },
  {
    cnpj: '10864690001667',
    name: 'Finlândia',
    fullName: 'Finlândia Corretora de Seguros Ltda',
    icon: {
      svg: iconFinladia,
      style: {
        height: 24,
        width: 24,
      },
    },
  },
  {
    cnpj: '61038592000125',
    name: 'Marsh',
    fullName: 'Marsh Corretora de Seguros Ltda',
    icon: {
      svg: iconMarsh,
      style: {
        height: 26,
        width: 26,
      },
    },
  },
  {
    cnpj: '48102552000137',
    name: 'Aon',
    fullName: 'Aon Holdings Corretora de Seguros Ltda',
    icon: {
      svg: iconAon,
      style: {
        height: 60,
        width: 90,
        marginLeft: 25,
      },
    },
  },
  {
    cnpj: '42278473000103',
    name: 'Wiz',
    fullName: 'Wiz Co Participações e Corretagem de Seguros S.A',
    icon: {
      svg: null,
      style: {},
    },
    img: {
      icon: iconWiz,
      styles: {
        width: 42.6,
        height: 17.86,
        marginRight: 3,
      },
    },
  },
]

export const EMPRESA_NAO_ENCONTRADA = 'Não encontrado na base de dados. Por favor, prossiga com o cadastro da empresa.'

export const JUDICIAL_APPEAL_INTERLOCUTORY = 1
export const JUDICIAL_APPEAL_EMBARGOES = 2
export const JUDICIAL_APPEAL_MAGAZINE_RESOURCE = 3
export const JUDICIAL_APPEAL_TERMINATION_ACTION = 4
export const JUDICIAL_APPEAL_EXTRAORDINARY_RESOURCE = 5
export const JUDICIAL_APPEAL_ORDINARY_RESOURCE = 6

export const PRODUCT_JUDICIAL_GUARANTEE_APPEAL = 2
export const PRODUCT_GUARANTEE_MODALITY = 3
export const PRODUCT_ENGINEERING_RISK = 4

export const ENDORSEMENT_TYPE_DATA_OR_OBJECT_CORRECTION = 1
export const ENDORSEMENT_TYPE_CANCELLATION = 2
export const ENDORSEMENT_TYPE_DEADLINE_EXTENSION = 3
export const ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT = 4
export const ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT = 5
export const ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT = 8
export const ENDORSEMENT_TYPE_REDUCTION_INSURED_AMOUNT = 9
export const ENDORSEMENT_TYPE_INCLUSION_COVERAGE = 10
export const ENDORSEMENT_TYPE_APPEAL_WRITE_OFF = 18

const constants = {
  DEFAULT_THEME,
  STATUS_QUOTATION,
  STATUS_QUOTATION_LIST,
  GUARANTEE_PERFORMANCE_PUBLIC,
  GUARANTEE_PERFORMANCE_PRIVATE,
  QUOTE_TYPE_AUTOMATIC,
  QUOTE_TYPE_MANUAL,
  QUOTE_BID,
  QUOTE_LEASE_BAIL,
  QUOTE_PERFORMANCE,
  QUOTE_JUDICIAL_APPEAL,
  STATUS_QUOTE_ANALIZING,
  STATUS_QUOTE_DENIED,
  STATUS_QUOTE_APPROVED,
  STATUS_QUOTE_EXPIRED,
  STATUS_PROPOSAL_REQUESTED,
  STATUS_PROPOSAL_DONE,
  STATUS_PROPOSAL_ACCEPTED,
  STATUS_PROPOSAL_EXPIRED,
  STATUS_POLICY_ISSUED,
  STATUS_ENDORSEMENT_ANALIZING,
  STATUS_ENDORSEMENT_DENIED,
  STATUS_ENDORSEMENT_ISSUED,
  YEARS_IN_FIRST_LOAD,
  MONTH_OPTIONS,
}

export default constants
